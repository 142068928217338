<template>
<Layout>
	  <center>
	    <h1>El evento solicitado no existe...</h1>
	    <p>
	      <a href="/">Ir a inicio?</a>
	    </p>
	  </center>
</Layout>
</template>

<script>

  export default {
    name: 'NotFound'
  }

</script>

<style scoped>

  center {
    margin: 15vw;
  }

  h1 {
    color: var(--border);
    font-size: 2em;
  }

</style>